import * as React from "react"
import Layout from "../../components/Layout"
import { Row, Col } from "react-bootstrap"
import { Link } from "gatsby"
import ImageDownload from "../../components/ImageDownload"

const IndexPage = () => {
    const root = "/img/download/leaders"

    return (
        <Layout pageTitle="Leaders - Standard Graphics">
            <Row>
                <Col>
                    <Link to="/leaders">Back</Link>
                </Col>
            </Row>
            
            <Row className="mt-2">
                <Col md={8}>
                    
                    <p className="para">Brows the Events section for more general graphics for your clubs. Here you will find graphics specific to the leader role.</p>

                    </Col>
            </Row>
            
            <ImageDownload
                root={root}
                name="Leader Promo"
                filename="leader-promo"
                text="Use this to promote joining as a leader"
                squareHref="https://www.canva.com/design/DAFqfwi6au8/qM2kG-UDPpLS2i6MGxhRSQ/view?utm_content=DAFqfwi6au8&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink&mode=preview"
                horizontalHref="https://www.canva.com/design/DAFANb_2MvQ/fEKMgPqRead4hixbzVyiTw/view?utm_content=DAFANb_2MvQ&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink&mode=preview"
                copyHref="https://womensbusiness.club/leaders"
            />

<ImageDownload
                root={root}
                name="Leader Promo 2"
                filename="leader-promo-2"
                text="Use this to promote joining the Coffee &amp; Coworking team as a leader"
                squareHref="https://www.canva.com/design/DAFEgLH6A_c/8NI5vEOHSdC7sLQRdMxqJw/view?utm_content=DAFEgLH6A_c&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink&mode=preview"
                horizontalHref="https://www.canva.com/design/DAFqfyYW6LY/JMlGjdC2mYDA-sg5zFuClg/view?utm_content=DAFqfyYW6LY&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink&mode=preview"
                copyHref="https://womensbusiness.club/leaders"
            />
        </Layout>
    )
}

export default IndexPage
