import React from "react"
import { Row, Col } from "react-bootstrap"

const ImageDownload = ({
    root,
    filename,
    name,
    text,
    text2,
    squareHref,
    horizontalHref,
    copyHref
}) => {
    return (
        <Row className="mt-5">
            <Col md={6}>
                <img
                    src={`${root}/${filename}.png`}
                    className="img-fluid"
                    alt=""
                    style={{ maxHeight: "350px" }}
                />
            </Col>
            <Col>
                <h3>{name}</h3>
                <p className="para" style={{ whiteSpace: "pre-wrap" }}>
                    {text}
                </p>
                <p className="para" style={{ whiteSpace: "pre-wrap" }}>
                    {text2}
                </p>
                {squareHref && (

                    <a
                    href={squareHref}
                    className="btn btn-primary m-3"
                    target="_blank"
                    >
                    Square Image
                </a>
                )}
                {horizontalHref && (
                    <a
                        href={horizontalHref}
                        className="btn btn-primary m-3"
                        target="_blank"
                    >
                    Horizontal Image
                    </a>
                )}
                 {copyHref && (
                    <a
                        href={copyHref}
                        className="btn btn-primary m-3"
                        target="_blank"
                    >
                    Copy
                    </a>
                )}
                
            </Col>
        </Row>
    )
}

export default ImageDownload
